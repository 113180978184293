import React, { useState, useEffect } from "react"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Backdrop from "@material-ui/core/Backdrop"
import Premium from "../../../images/footer-logo.png"
import CrossIcon from "../../../../static/images/cross-icon-racqy.svg"
import { COLORS } from "../../../utils/theme"
import Button from "@material-ui/core/Button"
import { useSelector, useDispatch } from "react-redux"
import { resendEmailVerification } from "../../../redux/slices/blockingUserSlice"
import { makeStyles, Typography } from "@material-ui/core"
import Spinner from "../Loader/Spinner"
import Spinner3 from "../Loader/Spinner3"
import { activeSubscription } from "../../../redux/slices/subscriptionModalSlice"
import { setProfileVerificationModal } from "../../../redux/slices/blockingUserSlice"


import Loader from "../Loader/Loader"

const useStyles = makeStyles(theme => ({
  createButton: {
    width: "74%",
    minWidth: "100px",
    minHeight: "40px",
    position: "absolute",
    bottom: "10px",
    fontSize: "12px",
    color: "white !important",
    textTransform: "capitalize !important",
    borderRadius: "8px !important",
    margin: "15px",
    background: "#45C165",
    "&:hover": {
      background: "#45C165",
    },
    padding: "3px 0px",
  },
  footer: {
    width: "100%",
    backgroundColor: "#F4F5F7",
    height: "10px",
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: COLORS.white,
    border: "0px",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: 24,
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "300px" /* Set a fixed height */,
    overflowY: "auto",
    border: "1px solid transparent !important",
    outline: "none",
  },
  ul: {
    color: COLORS.offBlack,
    fontWeight: "bold",
  },
  crossContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  crossIcon: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    cursor: "pointer",
  },
  premiumIcon: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    marginBottom: "20px",
  },
  "&:focus": {
    outline: "none",
  },
  loader: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default function ProfileVerificationModal() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isVisible = useSelector(
    state => state?.blockingUserSlice?.showProfileVerificationModal
  )
  const loading = useSelector(state => state?.blockingUserSlice?.isLoading)
  const profileVerifyLoader = useSelector(
    state => state?.blockingUserSlice?.verifyEmailLoader
  )

  const close = () => {
    dispatch(setProfileVerificationModal(false))
  }
  return (
    <Modal
      style={{ border: "1px solid red" }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={() => close()}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={true}>
        <Box border={"none"} className={classes.box}>
          {!profileVerifyLoader ? (
            <div>
              <div className={classes.crossContainer} onClick={close}>
                <img src={CrossIcon} className={classes.crossIcon} alt="" />
              </div>
              <div
                style={{
                  padding: "0px 20px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className={classes.premiumIcon}>
                  <img
                    src={Premium}
                    style={{
                      height: "40px",
                      width: "40px",
                    }}
                    alt=""
                  />
                </div>
                <Typography
                  id="transition-modal-title"
                  variant="h4"
                  component="h4"
                >
                  Verify your Account
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="p"
                  component="p"
                  style={{ marginTop: "20px" }}
                >
                  Your account is not verified please verify your account
                </Typography>

                {loading ? (
                  <Spinner />
                ) : (
                  <Button
                    color="yellow"
                    className={classes.createButton}
                    onClick={() =>
                      dispatch(
                        resendEmailVerification({
                          body: {},
                        })
                      )
                    }
                  >
                    Resend Verification Email
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.loader}>
              <Spinner3 />
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  )
}
