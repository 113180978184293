import React from "react"
import "./Spinner3.css"

const Spinner3 = () => {
  return (
    <div class="outer-div">
      <div class="lds-ring3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner3
